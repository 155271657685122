<!--This is the Admin page, with the list of all available users. -->
<template>
    <b-container fluid class="admin-container py-4">
        <b-row class="mb-4" v-if="$route.meta.showHeader">
            <b-col
                class="d-flex flex-column align-items-start flex-grow flex-sm-row align-items-sm-center"
            >
                <h1 class="text-primary mb-0">
                    {{ t('users') }}
                </h1>
            </b-col>
        </b-row>

        <router-view />

        <p class="text-right font-weight-lighter mt-4">
            <small>
                <span class="float-left">{{ t('disclaimer') }} </span> WebApp {{ appVersion }},
                ORION API V{{ apiVersion }}, {{ buildTimeStamp }}{{ calculatorUrl }}
            </small>
        </p>
    </b-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import {CALCULATOR_API} from '@/config';
import version from '@/assets/version.json';

export default {
    name: 'AdminPage',
    data() {
        return {
            appVersion: null,
        };
    },
    computed: {
        ...mapState({
            apiVersionInfo: (state) => state.apiversion.apiVersionInfo,
        }),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters({
            permissions: 'permissions/permissions',
        }),
        apiVersion() {
            if (this.apiVersionInfo && this.apiVersionInfo.version)
                return this.apiVersionInfo.version;

            return '';
        },
        buildTimeStamp() {
            if (this.apiVersionInfo && this.apiVersionInfo.buildTimeStamp)
                return this.apiVersionInfo.buildTimeStamp;

            return '';
        },
        calculatorUrl() {
            if (CALCULATOR_API.BASE_URL.includes('develop')) {
                return '';
            }
            if (CALCULATOR_API.BASE_URL.includes('sandbox')) {
                return ', SANDBOX';
            } else {
                return ', ' + CALCULATOR_API.BASE_URL;
            }
        },
        hasUsersPermission() {
            return this.checkPermissions({
                [PERMISSIONS.USERS]: PERMISSIONS_VALUES.READ_TYPES,
            });
        },
    },
    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
    },
    async mounted() {
        // Print out the front end version to the console everytime the user navigates to the adming page
        this.appVersion = version.info;
        console.log('WebApp ' + version.info);

        /* Print out the backend API version to the console everytime the user navigates to the admin page.
        If getter does not yet have the backend info from the asynchronous call, don't try to print it out.
        */
        if (this.apiVersionInfo) {
            console.log('API V' + this.apiVersionInfo.version, this.apiVersionInfo.buildTimeStamp);
        } else {
            await this.fetchApiInfo();
        }
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables';
@import '@/assets/css/typography';

::v-deep .router-link-active {
    text-decoration: underline;
    color: $primary;
}
.divider {
    width: 4px;
    background: $gray-light;
    height: 100%;
    margin: 0 30px 0 40px;
}

@media (max-width: 576px) {
    .divider {
        width: 50%;
        height: 4px;
        margin: 30px 0 20px 0;
    }
}
</style>
